.sj-powered-by {
  display: none !important;
}

.sj-card {
  background: var(--slate-800) !important;
}

.sj-content {
  color: var(--slate-300) !important;
}
